import type { BlockDto, BlocksDto } from "@annotate-dev/dtos";
import {
  DividerContainer,
  ProseItem,
  BlockContainer,
} from "../../shared/src/lib";
import { useEffect, useRef } from "react";
import invariant from "tiny-invariant";

import { useWalkthroughState } from "./state";

interface Props {
  blocks: BlocksDto;
  blocksIntersectionObserver: IntersectionObserver | null;
  scrollToElement: (el: HTMLElement) => void;
}

export default function BlocksSection({
  blocks,
  blocksIntersectionObserver,
  scrollToElement,
}: Props) {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { state } = useWalkthroughState();
  return (
    <div
      ref={sectionRef}
      className="divide-tokyo-border-subtle divide-y overflow-x-hidden [counter-reset:dividers]"
    >
      {blocks.map((b) => (
        <BlockItem
          key={b.id}
          block={b}
          blocksIntersectionObserver={blocksIntersectionObserver}
          focused={state.focusedBlockId === b.id}
          onClick={(e) => {
            scrollToElement(e.currentTarget);
          }}
        />
      ))}
    </div>
  );
}

interface BlockItemProps {
  block: BlockDto;
  blocksIntersectionObserver: IntersectionObserver | null;
  focused: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function BlockItem({
  block,
  blocksIntersectionObserver,
  focused,
  onClick,
}: BlockItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!blocksIntersectionObserver) return;
    invariant(ref.current);

    const el = ref.current;
    blocksIntersectionObserver.observe(el);

    return () => blocksIntersectionObserver.unobserve(el);
  }, [blocksIntersectionObserver]);

  if (block.type === "divider") {
    return (
      <BlockContainer
        className="cursor-pointer !border-t-transparent p-5"
        focused={focused}
        data-block-id={block.id}
        ref={ref}
        onClick={onClick}
      >
        <DividerContainer>
          <h2 className="text-xl font-semibold">{block.content}</h2>
        </DividerContainer>
      </BlockContainer>
    );
  }

  if (
    block.type === "note" ||
    block.type === "annotation" ||
    block.type === "image"
  ) {
    return (
      <BlockContainer
        className="cursor-pointer"
        ref={ref}
        data-block-id={block.id}
        focused={focused}
        onClick={onClick}
      >
        <ProseItem
          className="p-5"
          dangerouslySetInnerHTML={{ __html: block.content }}
        />
      </BlockContainer>
    );
  }

  console.warn(
    `Block type ${(block as { type: string }).type} is not supported by this version of the SDK. Skipping rendering of this block.`,
  );

  return null;
}
