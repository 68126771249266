interface Props {
  children: React.ReactNode;
}

export function FileTabsContainer({ children }: Props) {
  return (
    <div className="z-10 flex h-8 flex-none overflow-x-auto bg-tokyo-dark-900">
      {children}
    </div>
  );
}
