import clsx from "clsx";
import { forwardRef } from "react";

export const BlockContainer = forwardRef<
  HTMLDivElement,
  {
    focused: boolean;
    children: React.ReactNode;
  } & React.HTMLProps<HTMLDivElement>
>(({ focused, children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        className,
        "relative rounded-md border-l-4 transition-colors",
        focused
          ? "!border-l-tokyo-orange-300 bg-tokyo-dark-800"
          : "!border-l-transparent bg-transparent md:hover:!border-l-tokyo-orange-300/25 md:hover:bg-tokyo-dark-800/50",
      )}
      {...props}
    >
      {children}
    </div>
  );
});

BlockContainer.displayName = "BlockContainer";
