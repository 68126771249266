import type { BlockInfo, EditorView } from "@codemirror/view";
import type { MaybeUncommittedAnnotationBlockDto } from "@annotate-dev/dtos";

const BREATHER = 25;

interface ScrollToAnnotatedCodeParams {
  annotation: MaybeUncommittedAnnotationBlockDto;
  cmView: EditorView;
  prevOpenedCodeFileId: string | null;
}

export function scrollToAnnotatedCode({
  annotation,
  cmView,
  prevOpenedCodeFileId,
}: ScrollToAnnotatedCodeParams) {
  const fromLine = cmView.lineBlockAt(annotation.from);
  const toLine = cmView.lineBlockAt(annotation.to);

  const isAnnotationInDifferentFile =
    annotation.codeFileId !== prevOpenedCodeFileId;

  if (isAnnotationInDifferentFile) {
    cmView.scrollDOM.scrollTo({
      behavior: "auto",
      top: fromLine.top - BREATHER,
    });
    return;
  }

  if (
    isRangeOverflowingViewport(fromLine, toLine, cmView.scrollDOM) ||
    isRangeContainedInViewport(fromLine, toLine, cmView.scrollDOM)
  ) {
    return;
  }

  if (isLineBlockAboveViewPort(fromLine, cmView.scrollDOM)) {
    cmView.scrollDOM.scrollTo({
      behavior: "smooth",
      top: fromLine.top - BREATHER,
    });
  } else if (isLineBlockBelowViewPort(toLine, cmView.scrollDOM)) {
    cmView.scrollDOM.scrollTo({
      behavior: "smooth",
      top: Math.min(
        toLine.bottom - cmView.scrollDOM.offsetHeight + BREATHER,
        fromLine.top - BREATHER,
      ),
    });
  }
}

function isLineBlockAboveViewPort(
  lineBlock: BlockInfo,
  scrollDOM: HTMLElement,
): boolean {
  return scrollDOM.scrollTop > lineBlock.bottom;
}

function isLineBlockBelowViewPort(
  lineBlock: BlockInfo,
  scrollDOM: HTMLElement,
): boolean {
  return scrollDOM.scrollTop + scrollDOM.offsetHeight < lineBlock.top;
}

function isRangeOverflowingViewport(
  fromLineBlock: BlockInfo,
  toLineBlock: BlockInfo,
  scrollDOM: HTMLElement,
): boolean {
  return (
    scrollDOM.scrollTop > fromLineBlock.top &&
    toLineBlock.bottom > scrollDOM.scrollTop + scrollDOM.offsetHeight
  );
}

function isRangeContainedInViewport(
  fromLineBlock: BlockInfo,
  toLineBlock: BlockInfo,
  scrollDOM: HTMLElement,
) {
  const isStartBelowTopOfViewPort = !isLineBlockAboveViewPort(
    fromLineBlock,
    scrollDOM,
  );
  const isEndAboveBottomOfViewPort = !isLineBlockBelowViewPort(
    toLineBlock,
    scrollDOM,
  );

  return isStartBelowTopOfViewPort && isEndAboveBottomOfViewPort;
}
