import type { EditorView } from "@codemirror/view";
import { createContext, useContext, useReducer } from "react";

type Action =
  | {
      type: "focusFile";
      id: string;
    }
  | {
      type: "focusBlock";
      id: string;
    }
  | { type: "setEditor"; editor: EditorView };

export interface State {
  focusedFileId: string | null;
  focusedBlockId: string | null;
  cmView: EditorView | null;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "focusFile":
      return { ...state, focusedFileId: action.id };
    case "focusBlock":
      return {
        ...state,
        focusedBlockId: action.id,
      };
    case "setEditor":
      return { ...state, cmView: action.editor };
  }
}

const StateContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
} | null>(null);

export function StateProvider({
  children,
  defaultState,
}: {
  children: React.ReactNode;
  defaultState: State;
}) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
}

export function useWalkthroughState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useWalkthroughState must be used within a StateProvider");
  }

  return context;
}
