import { LanguageDescription } from "@codemirror/language";
import { languages } from "@codemirror/language-data";
import { tokyoNightStorm } from "@uiw/codemirror-theme-tokyo-night-storm";
import ReactCodeMirror from "@uiw/react-codemirror";
import type { BlocksDto, CodeFilesDto } from "@annotate-dev/dtos";
import { useEffect, useState } from "react";
import invariant from "tiny-invariant";

import {
  highlightState,
  setHighlightRangeEffect,
} from "./code-mirror/highlight";
import { useWalkthroughState } from "./state";

interface Props {
  codeFiles: CodeFilesDto;
  blocks: BlocksDto;
}

export default function EditorSection({ codeFiles, blocks }: Props) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { state, dispatch } = useWalkthroughState();

  const focusedFile = codeFiles.find((f) => f.id === state.focusedFileId);
  invariant(focusedFile);

  const languageDescription = LanguageDescription.matchFilename(
    languages,
    focusedFile.name,
  );

  useEffect(() => {
    if (!state.cmView) {
      return;
    }

    const focusedBlock =
      state.focusedBlockId && blocks.find((b) => b.id === state.focusedBlockId);

    if (!focusedBlock) {
      return;
    }

    if (
      focusedBlock.type === "annotation" &&
      focusedBlock.codeFileId === focusedFile.id
    ) {
      state.cmView.dispatch({
        effects: [
          setHighlightRangeEffect.of({
            from: focusedBlock.from,
            to: focusedBlock.to,
          }),
        ],
      });
    } else {
      state.cmView.dispatch({
        effects: [setHighlightRangeEffect.of(null)],
      });
    }
  }, [state.focusedBlockId, focusedFile, blocks, state.cmView]);

  if (!mounted) {
    return <div className="flex-1 bg-tokyo-dark-600" />;
  }

  return (
    <ReactCodeMirror
      value={focusedFile.content}
      onCreateEditor={(editor) => {
        // There's a bug in code mirror where occassionally the scroll position is not set correctly on the first render
        // (see https://discuss.codemirror.net/t/editor-starts-partially-scrolled-down/7567/10). To work around this we 'reset'
        // to the initial scroll position (top of file) after the code mirror view has been created.
        editor.dispatch({
          effects: [editor.scrollSnapshot()],
        });
        dispatch({ type: "setEditor", editor });
      }}
      extensions={[languageDescription?.support || [], highlightState]}
      theme={tokyoNightStorm}
      className="h-full flex-1 overflow-auto"
      editable={false}
      height="100%"
      basicSetup={{
        foldGutter: false,
      }}
    />
  );
}
