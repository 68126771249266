import { ProseItem, TitleAndDescriptionContainer } from "../../shared/src/lib";

interface Props {
  title: string;
  descriptionHtml: string;
  authorName: string;
  updatedAt: Date;
}

export default function TitleAndDescription({
  title,
  descriptionHtml,
  authorName,
  updatedAt,
}: Props) {
  const localizedUpdatedAt = updatedAt.toLocaleDateString("en", {
    dateStyle: "medium",
  });

  return (
    <TitleAndDescriptionContainer>
      <h1 className="p-5 text-3xl font-bold">{title}</h1>
      <address className="pl-5 text-sm not-italic">{authorName}</address>
      <time className="pl-5 text-sm" dateTime={updatedAt.toISOString()}>
        {localizedUpdatedAt}
      </time>
      <ProseItem
        className="p-5"
        dangerouslySetInnerHTML={{
          __html: descriptionHtml,
        }}
      />
    </TitleAndDescriptionContainer>
  );
}
