import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import { forwardRef } from "react";

interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "ref"> {
  isFocused: boolean;
  asChild?: boolean;
}

export const CodeFileTab = forwardRef<HTMLButtonElement, Props>(
  ({ asChild, isFocused, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        ref={ref}
        className={clsx(
          "h-8 items-center border border-tokyo-border-subtle text-sm text-tokyo-text-1",
          isFocused ? "bg-tokyo-dark-600" : " bg-tokyo-dark-900",
          { "border-b-transparent": isFocused },
        )}
        {...props}
      />
    );
  },
);

CodeFileTab.displayName = "CodeFileTab";
