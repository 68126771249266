import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import { forwardRef } from "react";

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, "ref"> {
  asChild?: boolean;
}

export const ProseItem = forwardRef<HTMLDivElement, Props>(
  ({ asChild, className, ...props }, ref) => {
    const Comp = asChild ? Slot : "div";
    return (
      <Comp
        ref={ref}
        className={clsx(
          "prose-headings:text-tokyo-heading prose prose-sm prose-invert max-w-[46rem] text-tokyo-text-1",
          className,
        )}
        {...props}
      ></Comp>
    );
  },
);

ProseItem.displayName = "ProseItem";
