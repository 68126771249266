import {
  type EditorState,
  RangeSet,
  RangeSetBuilder,
  StateEffect,
  StateField,
} from "@codemirror/state";

import {
  Decoration,
  EditorView,
  gutterLineClass,
  GutterMarker,
} from "@codemirror/view";

export const setHighlightRangeEffect = StateEffect.define<{
  from: number;
  to: number;
} | null>({
  map: (val, change) => {
    if (!val) {
      return null;
    }

    return { from: change.mapPos(val.from), to: change.mapPos(val.to) };
  },
});

export const highlightState = StateField.define<{
  from: number;
  to: number;
} | null>({
  create() {
    return null;
  },
  update(value, tr) {
    let result = value;
    for (const e of tr.effects) {
      if (e.is(setHighlightRangeEffect)) {
        // result = highlightDeco(tr, e.value);
        result = e.value;
      }
    }

    return result;
  },
  provide: (f) => [
    EditorView.decorations.compute([f], (v) => {
      const range = v.field(highlightState);
      if (range === null) {
        return Decoration.none;
      }

      const deco = highlightDeco(v, range);
      return deco;
    }),
    gutterLineClass.compute([f], (f) => {
      const range = f.field(highlightState);
      if (range === null) {
        return RangeSet.empty as RangeSet<typeof highlightGutter>;
      }

      const builder = new RangeSetBuilder<typeof highlightGutter>();
      const startLine = f.doc.lineAt(range.from);
      const endLine = f.doc.lineAt(range.to);

      for (
        let lineNumber = startLine.number;
        lineNumber <= endLine.number;
        lineNumber++
      ) {
        const line = f.doc.line(lineNumber);
        builder.add(line.from, line.from, highlightGutter);
      }

      return builder.finish();
    }),
  ],
});

const deEmphasize = Decoration.line({ attributes: { class: "de-emphasis" } });
const emphasize = Decoration.line({ attributes: { class: "emphasis" } });

function highlightDeco(
  state: EditorState,
  highlightRange: { from: number; to: number } | null,
) {
  if (highlightRange === null) {
    return RangeSet.empty as RangeSet<Decoration>;
  }

  const builder = new RangeSetBuilder<Decoration>();
  const startLine = state.doc.lineAt(highlightRange.from);
  const endLine = state.doc.lineAt(highlightRange.to);

  for (let lineNumber = 1; lineNumber < startLine.number; lineNumber++) {
    const line = state.doc.line(lineNumber);
    builder.add(line.from, line.from, deEmphasize);
  }

  for (
    let lineNumber = startLine.number;
    lineNumber <= endLine.number;
    lineNumber++
  ) {
    const line = state.doc.line(lineNumber);
    builder.add(line.from, line.from, emphasize);
  }

  for (
    let lineNumber = endLine.number + 1;
    lineNumber < state.doc.lines;
    lineNumber++
  ) {
    const line = state.doc.line(lineNumber);
    builder.add(line.from, line.from, deEmphasize);
  }

  const result = builder.finish();
  return result;
}

class HighlightedGutter extends GutterMarker {
  elementClass = "emphasis";
}

const highlightGutter = new HighlightedGutter();
