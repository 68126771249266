import type { BlocksDto } from "@annotate-dev/dtos";
import { useWalkthroughState } from "./state.js";
import invariant from "tiny-invariant";
import { useCallback, useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { scrollToAnnotatedCode } from "./scroll-to-code-annotation.js";

interface UseBlockIntersectionObserverParams {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  blocks: BlocksDto;
}

export const useBlocksIntersectionObserver = ({
  scrollContainerRef,
  blocks,
}: UseBlockIntersectionObserverParams) => {
  const { dispatch, state } = useWalkthroughState();

  const [intersectionObserver, setIntersectionObserver] =
    useState<IntersectionObserver | null>(null);

  useEffect(() => {
    invariant(scrollContainerRef.current);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) return;

          const el = entry.target as HTMLElement;
          const blockId = el.dataset["blockId"];

          if (state.focusedBlockId === blockId) {
            return;
          }

          invariant(blockId, "block id must be present in the element dataset");
          const block = blocks.find((b) => b.id === blockId);

          invariant(block);

          // flushSync to ensure that the state is updated before scrolling
          flushSync(() => {
            dispatch({
              type: "focusBlock",
              id: blockId,
            });

            if (block.type === "annotation") {
              dispatch({ type: "focusFile", id: block.codeFileId });
            }
          });

          if (block.type === "annotation") {
            invariant(state.cmView, "CM view does not exist");
            scrollToAnnotatedCode({
              annotation: block,
              cmView: state.cmView,
              prevOpenedCodeFileId: state.focusedFileId,
            });
          }
        });
      },
      {
        root: scrollContainerRef.current,
        rootMargin: "-50% 0px -50% 0px",
        threshold: 0,
      },
    );

    setIntersectionObserver(observer);
    return () => {
      observer.disconnect();
      setIntersectionObserver(null);
    };
  }, [
    dispatch,
    blocks,
    scrollContainerRef,
    state.cmView,
    state.focusedFileId,
    state.focusedBlockId,
  ]);

  return intersectionObserver;
};

export const useForceRender = () => {
  const [, set] = useState(0);

  const memoized = useCallback(() => {
    set((c) => c + 1);
  }, []);

  return memoized;
};
