"use client";

import { CodeFileTab, FileTabsContainer } from "@annotate-dev/shared";
import { LanguageDescription } from "@codemirror/language";
import { languages } from "@codemirror/language-data";
import { useEffect, useRef } from "react";
import invariant from "tiny-invariant";

import BlocksSection from "./blocks-section.js";
import EditorSection from "./editor-section.js";
import { getWalkthroughData } from "./get-walkthrough-data.js";
import { useBlocksIntersectionObserver, useForceRender } from "./hooks.js";
import { StateProvider, useWalkthroughState } from "./state.js";
import TitleAndDescription from "./title-and-description.js";
import { AnimatePresence } from "framer-motion";
import ImageOverlay from "./image-overlay.js";

type WalkthroughData = Awaited<ReturnType<typeof getWalkthroughData>>;

interface WalkthroughViewProps {
  data: WalkthroughData;
}

export default function WalkthroughView({ data }: WalkthroughViewProps) {
  return (
    <StateProvider
      defaultState={{
        cmView: null,
        focusedBlockId: null,
        focusedFileId: data.codeFiles[0]?.id ?? null,
      }}
    >
      <Wrapped data={data} />
    </StateProvider>
  );
}

interface WrappedProps {
  data: WalkthroughData;
}

function Wrapped({ data }: WrappedProps) {
  const { codeFiles, publishedBlocks, walkthrough } = data;

  const { state, dispatch } = useWalkthroughState();
  const focusedFile = codeFiles.find((f) => f.id === state.focusedFileId);
  const focusedBlock = publishedBlocks.find(
    (b) => b.id === state.focusedBlockId,
  );

  invariant(focusedFile);

  const languageDescription = LanguageDescription.matchFilename(
    languages,
    focusedFile.name,
  );

  const forceRerender = useForceRender();
  const blocksScrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    languageDescription?.load().then(() => {
      forceRerender();
    });
  }, [languageDescription, forceRerender]);

  const blocksIntersectionObserver = useBlocksIntersectionObserver({
    blocks: publishedBlocks,
    scrollContainerRef: blocksScrollContainerRef,
  });

  return (
    // Styles are scoped under ".annotate-walkthrough-root" to avoid conflicts with the host app.
    // So, we need an outer most div as a wrapper.
    <div style={{ display: "contents" }} className="annotate-walkthrough-root">
      <div className="@container h-full w-full">
        <div className="bg-tokyo-dark-900 text-tokyo-text-1 @3xl:flex-row flex h-full flex-col-reverse">
          <div
            // Add a margin to the bottom of the container to ensure the final block hits the 50% height mark, allowing block to be focused via intersection observer.
            // To achieve the margin, we add an :after pseudo-element with a height of 50% of the container's height.
            // Using mb-1/2 doesn't work because it's relative to the container's width, not height.
            className="@3xl:basis-2/5 after:contents-[''] flex-shrink-0 flex-grow-0 basis-3/5 overflow-auto after:block after:h-1/2"
            ref={blocksScrollContainerRef}
          >
            <div className="mb-1/2 @3xl:p-7 mx-auto max-w-[46rem]">
              <TitleAndDescription
                title={walkthrough.publishedVersion!.title}
                descriptionHtml={walkthrough.publishedVersion!.description}
                authorName={walkthrough.publishedVersion!.author.name!}
                updatedAt={new Date(walkthrough.publishedVersion!.updatedAt)}
              />
              <BlocksSection
                blocks={publishedBlocks}
                blocksIntersectionObserver={blocksIntersectionObserver}
                scrollToElement={(el) => {
                  if (!blocksScrollContainerRef.current) {
                    return;
                  }

                  const elTopRelativeToContainer =
                    el.offsetTop - blocksScrollContainerRef.current.offsetTop;
                  const scrollContainerMiddle =
                    blocksScrollContainerRef.current.clientHeight / 2;

                  const BUFFER = 25;
                  const scrollTo =
                    elTopRelativeToContainer - scrollContainerMiddle + BUFFER;

                  blocksScrollContainerRef.current?.scrollTo({
                    behavior: "smooth",
                    top: scrollTo,
                  });
                }}
              />
            </div>
          </div>
          <div className="@3xl:basis-3/5 flex flex-shrink-0 flex-grow-0 basis-2/5 flex-col overflow-y-auto relative">
            <FileTabsContainer>
              {codeFiles.map((file) => (
                <CodeFileTab
                  isFocused={state.focusedFileId === file.id}
                  key={file.id}
                  asChild
                >
                  <button
                    className="px-4"
                    onClick={() => dispatch({ type: "focusFile", id: file.id })}
                  >
                    {file.name}
                  </button>
                </CodeFileTab>
              ))}
            </FileTabsContainer>
            <EditorSection codeFiles={codeFiles} blocks={publishedBlocks} />
            <AnimatePresence>
              {focusedBlock?.type === "image" && (
                <ImageOverlay imageBlock={focusedBlock} />
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}
