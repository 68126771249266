interface Props {
  children: React.ReactNode;
}

export function DividerContainer({ children }: Props) {
  return (
    <div className="flex items-center gap-5 text-xs font-semibold [counter-increment:dividers] before:inline-block before:h-5 before:w-5 before:flex-shrink-0 before:flex-grow-0 before:rounded-full before:bg-tokyo-text-1 before:text-center before:font-semibold before:leading-[1.25rem] before:text-tokyo-dark-900 before:content-[counter(dividers)]">
      {children}
    </div>
  );
}
