import type { ImageBlockDto } from "@annotate-dev/dtos";
import { motion } from "framer-motion";

interface ImageOverlayProps {
  imageBlock: ImageBlockDto;
}

export default function ImageOverlay({ imageBlock }: ImageOverlayProps) {
  return (
    <motion.div
      className="absolute w-full h-full flex items-center justify-center bg-tokyo-dark-900/80 z-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <img
        className="max-w-full max-h-full object-contain"
        src={imageBlock.url}
        width={imageBlock.width}
        height={imageBlock.height}
      />
    </motion.div>
  );
}
