interface Props {
  children: React.ReactNode;
}

/**
 * We want the Title and Description to push the content below the middle of the viewport so that
 * the intersection observer (that looks for a block to intersect the 50% point of the viewport) can trigger the block focus.
 */
export function TitleAndDescriptionContainer({ children }: Props) {
  return (
    <div className="flex min-h-[50cqh] flex-col">
      <div className="flex-1" />
      <div className="flex-shrink-0 flex-grow-0">{children}</div>
      <div className="flex-1" />
    </div>
  );
}
